import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { ActionConfirmDirective } from './directives/action-confirm.directive';
import { IconComponent } from './icon.component';

@Component({
  selector: 'action-confirm',
  template: `<icon [svgIcon]="icon"></icon>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent],
})
export class ActionConfirmComponent extends ActionConfirmDirective {
  @Input()
  public icon = 'delete';
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-on-prefix
  @Output('onConfirm')
  public override confirmed: EventEmitter<Event> = new EventEmitter<Event>();
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-on-prefix
  @Output('onCancel')
  public override cancelled: EventEmitter<Event> = new EventEmitter<Event>();

  @HostBinding('attr.role')
  public readonly role = 'button';
}
